//公共参数文件
let url = "https://admin.resmartcharge.com/";
let sub_dir = "/";
let is_show_log = false;
let is_show_log_err = true;

if (process.env.VUE_APP_CI_COMMIT_REF_NAME !== "master") {
  switch (process.env.VUE_APP_CI_COMMIT_REF_NAME) {
    case 'local':
      url = "http://api.local.resmartcharge.com:10000/"; // 本地环境
      break;
    case 'dev':
      url = "https://api.dev.resmartcharge.com/"; // 开发环境
      break;
    case 'test':
      url = "https://api.test.resmartcharge.com/"; // 测试环境
      break;
  }
  sub_dir = "/";
  is_show_log = true;
  is_show_log_err = true;
}

module.exports = {
  baseURL: url,
  version: "1.5.2",
  subDir: sub_dir,
  apiTimeout: 30000,
  isShowLog: is_show_log,
  isShowLogErr: is_show_log_err,
  uploadsUrl: url + "admin/sys/Common/upload_img",
  queryFormLimit: [10, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
  webTitle: "新能慧充",
  DefaultAvatar: "img/default_avatar.jpg",
  pickerOptions: {
    shortcuts: [
      {
        text: "今天",
        onClick(picker) {
          const d = new Date();
          const end = new Date(d.getFullYear(), d.getMonth(), d.getDate());
          const start = new Date(d.getFullYear(), d.getMonth(), d.getDate());
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "昨天",
        onClick(picker) {
          const d = new Date(new Date().toDateString());
          const start = new Date(d);
          start.setDate(start.getDate() - 1);
          picker.$emit("pick", [start, start]);
        },
      },
      {
        text: "今月",
        onClick(picker) {
          const d = new Date();
          const end = new Date(d.getFullYear(), d.getMonth() + 1, 0);
          const start = new Date(d.getFullYear(), d.getMonth(), 1);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "上月",
        onClick(picker) {
          const d = new Date();
          const end = new Date(d.getFullYear(), d.getMonth(), 0);
          const start = new Date(d.getFullYear(), d.getMonth() - 1, 1);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "今年",
        onClick(picker) {
          const d = new Date();
          const end = new Date(d.getFullYear(), 12, 0);
          const start = new Date(d.getFullYear(), 0, 1);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "去年",
        onClick(picker) {
          const d = new Date();
          const end = new Date(d.getFullYear() - 1, 12, 0);
          const start = new Date(d.getFullYear() - 1, 0, 1);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "90天",
        onClick(picker) {
          const d = new Date();
          const end = new Date(d.getFullYear(), d.getMonth(), d.getDate());
          const start = new Date(
              d.getFullYear(),
              d.getMonth(),
              d.getDate() - 89
          );
          picker.$emit("pick", [start, end]);
        },
      },
    ],
  },
};
