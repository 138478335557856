/**
 * 检查设备环境
 * @returns {boolean} 是否为小程序或移动端环境
 */
export const checkDeviceEnvironment = () => {
    // 判断是否在小程序环境中
    let isMiniProgram = false;
    
    if (typeof window !== 'undefined') {
        // 检查 userAgent
        if (window.navigator && window.navigator.userAgent) {
            isMiniProgram = window.navigator.userAgent.includes('miniProgram');
        }
        
        // 检查微信小程序环境变量
        if (!isMiniProgram && window.__wxjs_environment === 'miniprogram') {
            isMiniProgram = true;
        }
        
        // 检查 WeixinJSBridge
        if (!isMiniProgram && typeof window.WeixinJSBridge !== 'undefined') {
            isMiniProgram = true;
        }
    }

    // 判断是否为移动端设备
    const isMobile = typeof window !== 'undefined' && (
        window.innerWidth <= 768 ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)
    );
    return isMiniProgram || isMobile;
};